<template>
    <Complaints></Complaints>
</template>

<script>
  import Complaints from "@/components/Admin/Complaints/Complaints.vue";

  export default {
    name: "ComplaintsView",
    components: { Complaints }
  };
</script>

<style scoped>

</style>