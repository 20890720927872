<template>
    <TablePlaceholder :data="data">
        <template #default="{ items, count }">
            <LTable
                ref="table"
                :data="items"
                :loading="isLoading"
                query-pagination
                :count="count"
                virtual-scroll
                backend-pagination
                backend-sorting
                hide-mobile
                with-options
                @sort="updateSortings"
                @update="GET_COMPLAINTS">
                <TableColumn
                    :label="$t('admin.complaints.table.labels.intId')"
                    centered
                    v-slot="{ row }">
                    {{ row.intId }}
                </TableColumn>

                <TableColumn
                    :label="$t('admin.complaints.table.labels.date')"
                    centered
                    field="createdAt"
                    v-slot="{ row }">
                    {{ moment(row.createdAt, "DD.MM.YYYY") }}
                </TableColumn>

                <TableColumn
                    :label="$t('admin.complaints.table.labels.creator')"
                    field="creator"
                    cell-class="nowrap"
                    v-slot="{ row: { creator } }">
                    <AdminOutput
                        :admin="creator"
                        class="mr-1">
                    </AdminOutput>
                </TableColumn>

                <TableColumn
                    :label="$t('admin.complaints.table.labels.reason')"
                    centered
                    cell-class="nowrap"
                    v-slot="{ row }">
                    {{ $t(`common.entity.incidenceReason.${ row.reason }`) }}
                </TableColumn>

                <TableColumn
                    :label="$t('admin.complaints.table.labels.webmaster')"
                    field="webmaster"
                    cell-class="nowrap"
                    v-slot="{ row: { webmaster } }">
                    <WebmasterOutput
                        :webmaster="webmaster"
                        class="mr-1">
                    </WebmasterOutput>
                </TableColumn>

                <TableColumn
                    :label="$t('admin.complaints.table.labels.offer')"
                    field="offer"
                    cell-class="nowrap"
                    v-slot="{ row: { offer: { detail: { name }, ...offer } } }">
                    <CustomOfferOutput
                        :offer="{ ...offer, name }"
                        class="mr-1">
                    </CustomOfferOutput>
                </TableColumn>

                <TableColumn
                    :label="$t('admin.complaints.table.labels.guaranteePercent')"
                    centered
                    cell-class="nowrap"
                    v-slot="{ row }">
                    <LTooltip
                        v-if="row.guaranteePercent"
                        :label="$t(`admin.complaints.table.buttons.guarantee`)"
                        position="is-left"
                        type="is-dark">
                        <router-link
                            target="_blank"
                            :to="{
                                name: 'admin:capacityAndBumps:log',
                                query: { filters: encodeFilters({
                                    offer: [row.offer.id],
                                    webmaster: [row.webmaster.id],
                                    guaranteeStatus: GuaranteeStatusEnum.ACTIVATED,
                                    type: typeEnum.GUARANTEE,
                                    datepicker: {
                                        dateStart: null,
                                        dateEnd: datepicker().dateEnd
                                    }
                                }) }
                            }">
                            <b-button
                                icon-left="search"
                                rounded
                                size="is-small"
                                type="is-gray is-light">
                            </b-button>
                        </router-link>
                    </LTooltip>

                    <template v-else>
                        {{ formatEmptyString(null) }}
                    </template>
                </TableColumn>

                <TableColumn
                    :label="$t(`admin.complaints.table.labels.capacity`)"
                    v-slot="{ row }">
                    <span>
                        {{ row.softCapacity }}
                    </span>
                </TableColumn>

                <TableColumn
                    :key="prefix"
                    v-for="prefix of ['negative', 'positive']"
                    centered
                    cell-class="nowrap"
                    :label="$t(`admin.complaints.table.labels.${ prefix }Period`)"
                    v-slot="{ row }">
                    <div
                        v-if="row[`${ prefix }StartDate`]"
                        class="is-flex is-justify-content-space-between is-align-items-center">
                        <div>
                            <span>
                                {{ moment(row[`${ prefix }StartDate`], "DD.MM.YYYY") }}
                            </span>
                            -
                            <span>
                                {{ moment(row[`${ prefix }EndDate`], "DD.MM.YYYY") }}
                            </span>
                        </div>

                        <b-tag
                            class="ml-1"
                            type="is-success is-light">
                            {{ fixedFormatPercent(row[`${ prefix }Conversion`]) }}%
                        </b-tag>
                    </div>
                    <div
                        v-else
                        class="has-text-centered"
                        style="width: 100%">
                        {{ formatEmptyString(null) }}
                    </div>
                </TableColumn>

                <TableColumn
                    :key="user"
                    v-for="user of ['creator', 'executor']"
                    :label="$t(`admin.complaints.table.labels.${ user }Comment`)"
                    v-slot="{ row }">
                    <ShowMore
                        modal
                        multiline
                        :data="defineLinksInString(row[`${ user }Comment`])"
                        :max-string="50">
                    </ShowMore>
                </TableColumn>

                <TableColumn
                    :label="$t('admin.complaints.table.labels.executor')"
                    field="executor"
                    v-slot="{ row: { executor } }">
                    <AdminOutput
                        :admin="executor"
                        class="mr-1">
                    </AdminOutput>
                </TableColumn>

                <TableColumn
                    :label="$t('admin.complaints.table.labels.priority')"
                    field="priority"
                    sortable
                    v-slot="{ row: { priority, id } }">
                    <CustomDropdown
                        :value="priority"
                        :list="priorityList"
                        :disabled="!useTrafficComplaintManage"
                        :is-loading="isLoadingPriority"
                        @input="updatePriority($event, id)">
                    </CustomDropdown>
                </TableColumn>

                <TableColumn
                    :label="$t('admin.complaints.table.labels.status')"
                    field="status"
                    v-slot="{ row }">
                    <ColoredStatus :type="ColoredStatusComplaintsEnum[row.status]">
                        {{ $t(`admin.complaints.filters.labels.statuses.${ row.status }`) }}
                    </ColoredStatus>
                </TableColumn>

                <b-table-column v-slot="{ row }">
                    <ComplaintsTableActions
                        :row="row">
                    </ComplaintsTableActions>
                </b-table-column>
            </LTable>
        </template>
    </TablePlaceholder>
</template>

<script>
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder.vue";
  import LTable from "@/components/Common/LTable.vue";
  import { mapActions, mapState } from "vuex";
  import { GET_COMPLAINTS, SET_PRIORITY_TO_TRAFFIC_COMPLAINT } from "@core/store/action-constants";
  import { UPDATE_SORTINGS } from "@core/store/mutation-constants";
  import { encodeFilters, formatEmptyString, moment } from "@core/filters";
  import AdminOutput from "@/components/Common/Output/AdminOutput.vue";
  import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
  import { fixedFormatPercent } from "@core/flowMethods";
  import ShowMore from "@/components/Common/ShowMore.vue";
  import ColoredStatus from "@/components/Common/ColoredStatus.vue";
  import { ColoredStatusComplaintsEnum } from "@core/store/types/admin/complaints/enums/ColoredStatusComplaintsEnum";
  import CustomOfferOutput from "@/components/Common/Output/CustomOfferOutput.vue";
  import ComplaintsTableActions from "@/components/Admin/Complaints/Views/ComplaintsTableActions.vue";
  import { defineLinksInString } from "@core/helpers/defineLinksInString";
  import CustomDropdown from "@/components/Common/DropdownOptions/CustomDropdown.vue";
  import { PriorityEnum } from "@core/store/types/admin/complaints/enums/PriorityEnum";
  import { hasPermissionsStrict } from "@core/mixins/permissions";
  import { typeEnum, statusEnum } from "@core/store/modules/admin/capacityAndBumps/datasets/logRequestEnums.json";
  import { datepicker } from "@core/helpers/datepicker";
  import { GuaranteeStatusEnum } from "@core/store/types/admin/capacityAndBumps/emuns/guaranteeStatusEnum";

  export default {
    name: "ComplaintsTable",
    components: {
      ComplaintsTableActions,
      CustomOfferOutput,
      CustomDropdown,
      ColoredStatus,
      ShowMore,
      WebmasterOutput,
      AdminOutput,
      LTable,
      TablePlaceholder
    },

    computed: {
      ...mapState("admin/complaints", {
        data: ({ data }) => data
      }),

      ...mapState({
        useTrafficComplaintManage: ({ auth }) => hasPermissionsStrict(["TRAFFIC_COMPLAINT.MANAGE"], auth.userPermissions)
      }),

      typeEnum () {
        return typeEnum;
      },

      statusEnum () {
        return statusEnum;
      },

      isLoading () {
        return this.$wait(`admin/complaints/${ GET_COMPLAINTS }`);
      },

      isLoadingPriority () {
        return this.$wait(`admin/complaints/${ SET_PRIORITY_TO_TRAFFIC_COMPLAINT }`);
      },

      ColoredStatusComplaintsEnum () {
        return ColoredStatusComplaintsEnum;
      },

      GuaranteeStatusEnum () {
        return GuaranteeStatusEnum;
      },

      priorityList () {
        return [
          { value: PriorityEnum.NORMAL, type: "is-success is-light", icon: "exclamation-triangle", text: this.$t("common.entity.priority.NORMAL") },
          { value: PriorityEnum.MAJOR, type: "is-warning is-light", icon: "exclamation-triangle", text: this.$t("common.entity.priority.MAJOR") },
          { value: PriorityEnum.CRITICAL, type: "is-danger is-light", icon: "exclamation-triangle", text: this.$t("common.entity.priority.CRITICAL") },
          { value: PriorityEnum.UNSET, type: "is-info is-light", icon: "exclamation-triangle", text: this.$t("common.entity.priority.UNSET") }
        ];
      }
    },

    methods: {
      encodeFilters,
      datepicker,
      ...mapActions("admin/complaints", {
        SET_PRIORITY_TO_TRAFFIC_COMPLAINT,
        GET_COMPLAINTS,
        UPDATE_SORTINGS
      }),

      async updateSortings (sort, order) {
        if (sort && order) {
          this.UPDATE_SORTINGS({ sort, order });
          await this.GET_COMPLAINTS();
        }
      },

      updatePriority (priority, complaintId) {
        this.SET_PRIORITY_TO_TRAFFIC_COMPLAINT({ complaintId, priority });
      },

      moment,
      formatEmptyString,
      defineLinksInString,
      fixedFormatPercent
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .has-background-transparent {
            background-color: transparent;
        }

        .table {
            th {
                &.is-sortable:hover {
                    border-color: transparent;
                }
            }
        }
    }
</style>