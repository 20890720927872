






















































import { defineEmits, defineProps } from "@vue/runtime-dom";
import { computed, ref } from "@vue/composition-api";
import { TranslateResult } from "vue-i18n";
interface listItem {
  value: string;
  type: string;
  icon: string;
  text: TranslateResult;
  pack?: string;
}
interface Props {
  value: string | null;
  list: listItem[];
  isLoading?: boolean;
  disabled?: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: true,
    type: [String, null]
  },
  list: {
    key: "list",
    required: true,
    type: Array
  },
  isLoading: {
    key: "isLoading",
    required: false,
    type: Boolean
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const _value = computed({
    get: () => props.value ?? props.list.find(item => item.value === props.value),
    set: value => emit("input", value)
  });
  const isActive = ref(false);
  const selectedElement = computed(() => props.list.find(item => item.value === props.value));
  return {
    _value,
    isActive,
    selectedElement
  };
};
export default __sfc_main;
