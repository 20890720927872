<template>
    <SectionWrapper
        v-if="nutra"
        :action-button-options="actionButtonOptions"
        @action="isActive = true">
        <template #verticals>
            <VerticalsFilter view-only></VerticalsFilter>
        </template>
        <template #sticky>
            <ComplaintsFilters></ComplaintsFilters>
        </template>
        <ComplaintsTable></ComplaintsTable>
        <transition name="fade">
            <ComplaintsModal v-if="isActive"></ComplaintsModal>
            <ComplaintsChatModal v-if="isActiveChart"></ComplaintsChatModal>
        </transition>
    </SectionWrapper>
</template>

<script>
  import SectionWrapper from "@/components/Common/SectionWrapper.vue";
  import ComplaintsFilters from "@/components/Admin/Complaints/ComplaintsFilters.vue";
  import ComplaintsTable from "@/components/Admin/Complaints/ComplaintsTable.vue";
  import ComplaintsModal from "@/components/Admin/Complaints/ComplaintsModal.vue";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_FILTERS, UPDATE_MODAL } from "@core/store/mutation-constants";
  import { mapActions, mapGetters, mapState } from "vuex";
  import { SET_EMPTY } from "@core/store/action-constants";
  import ComplaintsChatModal from "@/components/Admin/Complaints/ComplaintsChatModal.vue";
  import VerticalsFilter from "@/components/Common/Fields/VerticalsFilter.vue";

  export default {
    name: "Complaints",
    permissions: [
      "TRAFFIC_COMPLAINT.LIST.ALL",
      "TRAFFIC_COMPLAINT.LIST.OWN",
      "TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT"
    ],

    components: { VerticalsFilter, ComplaintsChatModal, ComplaintsModal, ComplaintsTable, ComplaintsFilters, SectionWrapper },

    created () {
      const { countryId } = this.$route.query;
      const { countries } = this.user;
      const adminCountryIds = countries?.length ? countries.map(item => item.id) : null;

      this.UPDATE_FILTERS({ countryId: countryId ?? adminCountryIds });
    },

    computed: {
      ...mapGetters(["user"]),

      ...mapFields("admin/complaints/complaintsModal", {
        fields: ["isActive"],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapGetters("verticals", ["nutra"]),
        
      ...mapState("admin/complaints/complaintsChatModal", {
        isActiveChart: ({ isActive }) => isActive
      }),

      actionButtonOptions () {
        return {
          permissions: ["INCIDENCE.VIEW"],
          label: this.$t("admin.complaints.modal.title")
        };
      }
    },

    methods: {
      ...mapActions("admin/complaints", {
        SET_EMPTY,
        UPDATE_FILTERS
      })
    },

    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>

</style>