export enum ReasonEnum {
  NOT_FILLED = "NOT_FILLED",
  INSIGNIFICANT = "INSIGNIFICANT",
  LEAD_OVERFLOW = "LEAD_OVERFLOW",
  PAYMENT_SYSTEM_FAIL = "PAYMENT_SYSTEM_FAIL",
  FACEBOOK_BAN = "FACEBOOK_BAN",
  OFFER_STOP = "OFFER_STOP",
  BAD_WEBMASTER_TRAFFIC = "BAD_WEBMASTER_TRAFFIC",
  BAD_WEBMASTER_PROFIT = "BAD_WEBMASTER_PROFIT",
  FORCE_MAJEURE = "FORCE_MAJEURE",
  WAITING_PROMO = "WAITING_PROMO",
  GUARANTEE_NOT_APPROVED = "GUARANTEE_NOT_APPROVED",
  BAD_GOOD_CONVERSION = "BAD_GOOD_CONVERSION",
  BAD_PROMO_CONVERSION = "BAD_PROMO_CONVERSION",
  RATE_NOT_APPROVED = "RATE_NOT_APPROVED",
  MANAGER_VACATION = "MANAGER_VACATION",
  WEBMASTER_PREPARING = "WEBMASTER_PREPARING",
  LOW_APPROVE = "LOW_APPROVE",
  NOT_ENOUGH_APPROVE = "NOT_ENOUGH_APPROVE",
  NO_ADVERTISER_BALANCE = "NO_ADVERTISER_BALANCE",
  LOW_TRAFFIC_IN_SOURCE = "LOW_TRAFFIC_IN_SOURCE",
  USE_PROHIBITED_PROMO = "USE_PROHIBITED_PROMO",
  TRAFFIC_QUALITY_PROBLEM = "TRAFFIC_QUALITY_PROBLEM",
  OTHER = "OTHER"
}