<template>
    <LTooltip
        class="is-inline-block"
        :label="tooltipLabel || $t('common.files.tooltips.createUpload')"
        :position="tooltipPosition"
        :type="tooltipType">
        <b-button
            :loading="isLoading"
            :disabled="disabled"
            icon-left="file-download"
            type="is-info is-light"
            @click="UPDATE_MODAL_ACTIVE(true)">
        </b-button>
    </LTooltip>
</template>

<script>
  import { UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
  import { mapActions } from "vuex";
  
  export default {
    name: "UploadFileButton",

    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      tooltipLabel: {
        type: String,
        default: null
      },
      tooltipPosition: {
        type: String,
        default: "is-left"
      }
    },

    computed: {
      tooltipType () {
        return this.disabled ? "is-danger" : "is-info";
      }
    },

    methods: {
      ...mapActions("uploads/uploadFile", {
        UPDATE_MODAL_ACTIVE
      })
    }
  };
</script>

<style scoped>

</style>
