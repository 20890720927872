<template>
    <ModalBox
        :is-active="isUploadFileModalActive"
        @close="UPDATE_MODAL_ACTIVE(false)"
        @submit.prevent.stop="submit">
        <template #header>
            <h5 class="title is-5">
                {{ $t("common.files.modal.title") }}
            </h5>
        </template>
        <b-notification
            v-if="uploadFileId"
            :closable="false"
            type="is-danger">
            {{ $t("common.files.overwriteWarning") }}
        </b-notification>
        <section class="card-body">
            <div class="columns">
                <div class="column">
                    <b-field :label="$t('common.files.modal.fields')">
                        <Select
                            v-model="fields"
                            :get-data="getUploadsFields"
                            :placeholder="$t('common.files.modal.fields')"
                            :searchable="false"
                            class="white"
                            field="label"
                            multiple
                            prop="value">
                            <template #text="{ option }">
                                {{ $t(option.label) }}
                            </template>
                            <template #selected-option="{ option }">
                                {{ $t(option.label) }}
                            </template>
                        </Select>
                    </b-field>
                    
                    <b-field :label="$t('common.files.modal.format')">
                        <Select
                            v-model="format"
                            :get-data="getFormatFile"
                            :placeholder="$t('common.files.modal.format')"
                            :searchable="false"
                            class="white"
                            field="label"
                            prop="value">
                        </Select>
                    </b-field>
                </div>
            </div>
        </section>
        <template #footer>
            <b-button
                :disabled="isDisabled"
                :loading="isLoading"
                class="is-fullwidth is-success is-light"
                native-type="submit">
                {{ $t("common.files.modal.buttons.create") }}
            </b-button>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import {
    GET_FORMAT_FILE,
    GET_UPLOADS_FIELDS,
    UPDATE_MODAL_ACTIVE,
    UPDATE_MODAL_OPTIONS,
    UPLOAD_FILE
  } from "@core/store/action-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";
  import { GET_UPLOADS_FILE_READY_PERCENT, SET_UPLOAD_FILE_ID } from "@core/store/action-constants";

  export default {
    name: "UploadFileModal",
    components: {
      ModalBox,
      Select
    },

    props: {
      query: {
        type: String,
        default: null
      },

      filters: {
        type: Object,
        default: null
      },
  
      arguments: {
        type: Object,
        default: null
      },

      initialFields: {
        type: Array,
        default: null
      }
    },

    computed: {
      ...mapFields("uploads/uploadFile", {
        fields: ["format", "fields"],
        base: "options",
        action: UPDATE_MODAL_OPTIONS
      }),
      
      ...mapState("uploads/uploadFile", {
        formatFileList: state => state.formatFileList.data,
        uploadsFieldsList: state => state.uploadsFieldsList.data,
        isUploadFileModalActive: state => state.isUploadFileModalActive
      }),
  
      ...mapState("uploads", {
        uploadFileId: state => state.uploadFileId
      }),

      isDisabled () {
        return this.fields?.length === 0 || !this.format;
      },

      isLoading () {
        return this.$wait(`uploads/uploadFile/${ UPLOAD_FILE }`);
      }
    },

    methods: {
      ...mapActions("uploads/uploadFile", {
        GET_UPLOADS_FIELDS: `uploadsFieldsList/${ GET_UPLOADS_FIELDS }`,
        GET_FORMAT_FILE: `formatFileList/${ GET_FORMAT_FILE }`,
        UPDATE_MODAL_OPTIONS,
        UPDATE_MODAL_ACTIVE,
        UPLOAD_FILE
      }),

      ...mapActions("uploads", {
        SET_UPLOAD_FILE_ID,
        GET_UPLOADS_FILE_READY_PERCENT
      }),

      async submit () {
        try {
          const id = await this.UPLOAD_FILE();
          this.UPDATE_MODAL_ACTIVE(false);

          this.$buefy.toast.open({
            message: this.$t("common.files.toasts.success"),
            type: "is-success"
          });
          this.SET_UPLOAD_FILE_ID(id);
          this.GET_UPLOADS_FILE_READY_PERCENT();
        } catch (e) {
          this.$buefy.toast.open({
            message: this.$t("common.files.toasts.fail"),
            type: "is-danger"
          });
        }
      },
  
      getUploadsFields () {
        return {
          items: this.initialFields,
          count: this.initialFields?.length
        };
      },
  
      getFormatFile () {
        this.GET_FORMAT_FILE();
        return this.formatFileList;
      }
  
    },
  
    watch: {
      isUploadFileModalActive (value) {
        if (value) {
          this.UPDATE_MODAL_OPTIONS({
            query: this.query,
            fields: this.initialFields.map(field => field.value),
            filters: this.filters,
            arguments: this.arguments
          });
        }
      }
    }
  };
</script>

<style scoped>

</style>
