<template>
    <LFilters
        :filters="filters"
        :loading="isLoading"
        @clear="SET_EMPTY('filters')"
        @confirm="confirmFilters">
        <template #filters>
            <div class="columns is-multiline is-real-mobile-flex">
                <FilterWrapper>
                    <CustomDatepicker
                        v-model="datepicker">
                    </CustomDatepicker>
                </FilterWrapper>
                
                <FilterWrapper>
                    <b-field :label="$t('common.entity.filters.id')">
                        <LInput
                            v-model.number="intId"
                            :placeholder="$t('common.entity.filters.id')">
                        </LInput>
                    </b-field>
                </FilterWrapper>

                <AccessControlFilter
                    v-model="creatorId"
                    type="manager"
                    multiple
                    :namespace-module="`admin/complaints/managersFilters`"
                    :label="$t(`common.entity.filters.creator`)"
                    :placeholder="$t(`common.entity.filters.creator`)">
                </AccessControlFilter>

                <AccessControlFilter
                    v-model="executorId"
                    type="manager"
                    multiple
                    :label="$t(`common.entity.filters.executor`)"
                    :placeholder="$t(`common.entity.filters.executor`)">
                </AccessControlFilter>

                <AccessControlFilter
                    v-model="offerId"
                    type="offer"
                    multiple
                    vertical
                    :placeholder="$t(`common.entity.filters.offer`)">
                </AccessControlFilter>

                <AccessControlFilter
                    v-model="webmasterId"
                    type="webmaster"
                    multiple
                    :placeholder="$t(`common.entity.filters.webmaster`)">
                </AccessControlFilter>

                <FilterWrapper>
                    <b-field :label="$t(`common.entity.filters.status`)">
                        <Select
                            v-model="status"
                            :get-data-vuex="getStatuses"
                            :searchable="false"
                            field="label"
                            prop="value"
                            :placeholder="$t(`common.entity.filters.status`)">
                        </Select>
                    </b-field>
                </FilterWrapper>

                <FilterWrapper>
                    <b-field :label="$t(`common.entity.filters.country`)">
                        <CountriesSelect
                            v-model="countryId"
                            multiple
                            :placeholder="$t(`common.entity.filters.country`)">
                        </CountriesSelect>
                    </b-field>
                </FilterWrapper>

                <FilterWrapper>
                    <b-field label="inHouse">
                        <InHouseSelect
                            v-model="isInHouse"
                            :searchable="false"
                            placeholder="inHouse">
                        </InHouseSelect>
                    </b-field>
                </FilterWrapper>

                <FilterWrapper>
                    <b-field :label="$t(`admin.complaints.filters.labels.priority`)">
                        <Select
                            v-model="priority"
                            :get-data-vuex="getPriority"
                            :placeholder="$t(`admin.complaints.filters.labels.priority`)"
                            :searchable="false"
                            field="label"
                            prop="value">
                        </Select>
                    </b-field>
                </FilterWrapper>

                <Permissions :permissions="['TRAFFIC_COMPLAINT.MANAGE', 'TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT']">
                    <FilterWrapper>
                        <!-- eslint-disable-next-line no-irregular-whitespace -->
                        <b-field label=" ">
                            <CustomCheckbox
                                v-model="hasNewMessages"
                                switchable
                                :label="$t('admin.complaints.filters.labels.newMassages')">
                            </CustomCheckbox>
                        </b-field>
                    </FilterWrapper>
                </Permissions>
            </div>
        </template>

        <template #top-right>
            <UploadFileButton :is-loading="isLoading"></UploadFileButton>
        </template>

        <UploadFileModal
            :filters="filters"
            :initial-fields="filteredSelectedFields"
            query="trafficComplaint">
        </UploadFileModal>
    </LFilters>
</template>

<script>
  import LFilters from "@/components/Common/LFilters.vue";
  import { mapActions, mapState } from "vuex";
  import { GET_COMPLAINTS, SET_EMPTY } from "@core/store/action-constants";
  import { UPDATE_FILTERS } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
  import AccessControlFilter from "@/components/Admin/Select/AccessControlFilter.vue";
  import { StatusComplaintsEnum } from "@core/store/types/admin/complaints/enums/StatusComplaintsEnum";
  import Select from "@/components/Common/Select/Select.vue";
  import UploadFileModal from "@/components/Common/NavigationBar/Uploads/UploadFileModal.vue";
  import UploadFileButton from "@/components/Common/UploadFileButton.vue";
  import CountriesSelect from "@/components/Common/Select/CountriesSelect.vue";
  import InHouseSelect from "@/components/Common/Select/InHouseSelect.vue";
  import CustomCheckbox from "@/components/Common/Controls/CustomCheckbox.vue";
  import { hasPermissions } from "@core/mixins/permissions";
  import Permissions from "@/components/Common/Permissions.vue";
  import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
  import { PriorityEnum } from "@core/store/types/admin/complaints/enums/PriorityEnum";

  export default {
    name: "ComplaintsFilters",
    components: {
      Permissions,
      CustomCheckbox,
      InHouseSelect,
      CountriesSelect,
      UploadFileButton,
      UploadFileModal,
      Select,
      AccessControlFilter,
      CustomDatepicker,
      LFilters
    },

    computed: {
      VerticalsEnum () {
        return VerticalsEnum;
      },
      ...mapState({
        isComplaint: ({ auth }) => !hasPermissions(["TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT"], auth.userPermissions) ||
          hasPermissions(["TRAFFIC_COMPLAINT.MANAGE"], auth.userPermissions)
      }),

      ...mapState("admin/complaints", {
        filters: ({ filters }) => filters,
        selectedFields: ({ selectedFields }) => selectedFields
      }),

      ...mapState({
        isCallCentersManager: ({ auth }) => hasPermissions(["TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT"], auth.userPermissions)
      }),

      ...mapFields("filtersConfirm", {
        fields: [
          "datepicker", "intId", "creatorId", "executorId", "offerId", "webmasterId",
          "status", "countryId", "isInHouse", "hasNewComplaintComment", "hasNewCallCenterComment",
          "priority"
        ],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      filteredSelectedFields () {
        return this.selectedFields.filter(field => {
          return !(field.value === "webmaster.intId" && this.isCallCentersManager);
        });
      },

      isLoading () {
        return this.$wait(`admin/complaints/${ GET_COMPLAINTS }`);
      },

      hasNewMessages: {
        get () {
          return this.isComplaint ? this.hasNewCallCenterComment : this.hasNewComplaintComment;
        },
        set (event) {
          event = event || null;
          this.isComplaint ? this.hasNewCallCenterComment = event : this.hasNewComplaintComment = event;
        }
      }
    },
      
    methods: {
      ...mapActions("admin/complaints", {
        SET_EMPTY,
        UPDATE_FILTERS,
        GET_COMPLAINTS
      }),

      confirmFilters ({ filters }) {
        this.UPDATE_FILTERS(filters);
        this.GET_COMPLAINTS();
      },

      getPriority () {
        const items = Object.values(PriorityEnum).map(value => ({ value, label: this.$t(`common.entity.priority.${ value }`) }));
        return { items, count: items.length };
      },

      getStatuses () {
        const items = Object.values(StatusComplaintsEnum).map(value => ({ label: this.$t(`admin.complaints.filters.labels.statuses.${ value }`), value }));
        return { items, count: items.length };
      }
    }
  };
</script>

<style scoped>

</style>