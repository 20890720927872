function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TablePlaceholder',{attrs:{"data":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{ref:"table",attrs:{"data":items,"loading":_vm.isLoading,"query-pagination":"","count":count,"virtual-scroll":"","backend-pagination":"","backend-sorting":"","hide-mobile":"","with-options":""},on:{"sort":_vm.updateSortings,"update":_vm.GET_COMPLAINTS}},[_c('TableColumn',{attrs:{"label":_vm.$t('admin.complaints.table.labels.intId'),"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.intId)+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t('admin.complaints.table.labels.date'),"centered":"","field":"createdAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.moment(row.createdAt, "DD.MM.YYYY"))+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t('admin.complaints.table.labels.creator'),"field":"creator","cell-class":"nowrap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var creator = ref.row.creator;
return [_c('AdminOutput',{staticClass:"mr-1",attrs:{"admin":creator}})]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t('admin.complaints.table.labels.reason'),"centered":"","cell-class":"nowrap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(("common.entity.incidenceReason." + (row.reason))))+" ")]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t('admin.complaints.table.labels.webmaster'),"field":"webmaster","cell-class":"nowrap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var webmaster = ref.row.webmaster;
return [_c('WebmasterOutput',{staticClass:"mr-1",attrs:{"webmaster":webmaster}})]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t('admin.complaints.table.labels.offer'),"field":"offer","cell-class":"nowrap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row_offer = ref.row.offer;
var name = ref_row_offer.detail.name;
var rest = objectWithoutProperties( ref_row_offer, ["detail"] );
var offer = rest;
return [_c('CustomOfferOutput',{staticClass:"mr-1",attrs:{"offer":Object.assign({}, offer, {name: name})}})]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t('admin.complaints.table.labels.guaranteePercent'),"centered":"","cell-class":"nowrap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.guaranteePercent)?_c('LTooltip',{attrs:{"label":_vm.$t("admin.complaints.table.buttons.guarantee"),"position":"is-left","type":"is-dark"}},[_c('router-link',{attrs:{"target":"_blank","to":{
                            name: 'admin:capacityAndBumps:log',
                            query: { filters: _vm.encodeFilters({
                                offer: [row.offer.id],
                                webmaster: [row.webmaster.id],
                                guaranteeStatus: _vm.GuaranteeStatusEnum.ACTIVATED,
                                type: _vm.typeEnum.GUARANTEE,
                                datepicker: {
                                    dateStart: null,
                                    dateEnd: _vm.datepicker().dateEnd
                                }
                            }) }
                        }}},[_c('b-button',{attrs:{"icon-left":"search","rounded":"","size":"is-small","type":"is-gray is-light"}})],1)],1):[_vm._v(" "+_vm._s(_vm.formatEmptyString(null))+" ")]]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t("admin.complaints.table.labels.capacity")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.softCapacity)+" ")])]}}],null,true)}),_vm._l((['negative', 'positive']),function(prefix){return _c('TableColumn',{key:prefix,attrs:{"centered":"","cell-class":"nowrap","label":_vm.$t(("admin.complaints.table.labels." + prefix + "Period"))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [(row[(prefix + "StartDate")])?_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.moment(row[(prefix + "StartDate")], "DD.MM.YYYY"))+" ")]),_vm._v(" - "),_c('span',[_vm._v(" "+_vm._s(_vm.moment(row[(prefix + "EndDate")], "DD.MM.YYYY"))+" ")])]),_c('b-tag',{staticClass:"ml-1",attrs:{"type":"is-success is-light"}},[_vm._v(" "+_vm._s(_vm.fixedFormatPercent(row[(prefix + "Conversion")]))+"% ")])],1):_c('div',{staticClass:"has-text-centered",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.formatEmptyString(null))+" ")])]}}],null,true)})}),_vm._l((['creator', 'executor']),function(user){return _c('TableColumn',{key:user,attrs:{"label":_vm.$t(("admin.complaints.table.labels." + user + "Comment"))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('ShowMore',{attrs:{"modal":"","multiline":"","data":_vm.defineLinksInString(row[(user + "Comment")]),"max-string":50}})]}}],null,true)})}),_c('TableColumn',{attrs:{"label":_vm.$t('admin.complaints.table.labels.executor'),"field":"executor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var executor = ref.row.executor;
return [_c('AdminOutput',{staticClass:"mr-1",attrs:{"admin":executor}})]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t('admin.complaints.table.labels.priority'),"field":"priority","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var ref_row = ref.row;
                        var priority = ref_row.priority;
                        var id = ref_row.id;
return [_c('CustomDropdown',{attrs:{"value":priority,"list":_vm.priorityList,"disabled":!_vm.useTrafficComplaintManage,"is-loading":_vm.isLoadingPriority},on:{"input":function($event){return _vm.updatePriority($event, id)}}})]}}],null,true)}),_c('TableColumn',{attrs:{"label":_vm.$t('admin.complaints.table.labels.status'),"field":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('ColoredStatus',{attrs:{"type":_vm.ColoredStatusComplaintsEnum[row.status]}},[_vm._v(" "+_vm._s(_vm.$t(("admin.complaints.filters.labels.statuses." + (row.status))))+" ")])]}}],null,true)}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('ComplaintsTableActions',{attrs:{"row":row}})]}}],null,true)})],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }