<template>
    <form @submit.prevent.stop="submit">
        <div
            class="modal-card"
            style="width: 40vw;">
            <header class="modal-card-head">
                <h5 class="title is-5">
                    {{ $t("admin.complaints.modal.titleClose") }}
                </h5>
            </header>

            <section class="modal-card-body">
                <b-field :label="$t('admin.complaints.modal.body.status')">
                    <Select
                        v-model="status"
                        :get-data-vuex="getStatuses"
                        :placeholder="$t(`admin.complaints.modal.body.reason`)"
                        required
                        class="white"
                        field="label"
                        prop="value">
                    </Select>
                </b-field>
                <b-field :label="$t('admin.complaints.modal.body.executorComment')">
                    <LInput
                        v-model="comment"
                        maxlength="5000"
                        required
                        type="textarea">
                    </LInput>
                </b-field>
            </section>

            <footer class="modal-card-foot">
                <div class="flex is-justify-content-end">
                    <b-button
                        class="is-info is-fullwidth"
                        native-type="submit">
                        {{ $t(`common.buttons.send`) }}
                    </b-button>
                </div>
            </footer>
        </div>

        <LLoading
            :active="isLoading"
            :is-full-page="false"></LLoading>
    </form>
</template>

<script>
  import LLoading from "@/components/Common/LLoading.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import { StatusComplaintsEnum } from "@core/store/types/admin/complaints/enums/StatusComplaintsEnum";

  export default {
    name: "ComplaintCloseTrafficModalTemplate",
    components: { LLoading, Select },

    props: {
      isLoading: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        status: StatusComplaintsEnum.DISPUTE_CALL_CENTER,
        comment: null
      };
    },

    methods: {
      getStatuses () {
        const { DISPUTE_CALL_CENTER, CALL_CENTER, TRAFFIC, NON_RELEVANT } = StatusComplaintsEnum;

        const items = [
          {
            label: this.$t(`admin.complaints.filters.labels.statuses.${ CALL_CENTER }`),
            value: DISPUTE_CALL_CENTER
          },
          {
            label: this.$t(`admin.complaints.filters.labels.statuses.${ TRAFFIC }`),
            value: TRAFFIC
          },
          {
            label: this.$t(`admin.complaints.filters.labels.statuses.${ NON_RELEVANT }`),
            value: NON_RELEVANT
          }
        ];

        return { items, count: items.length };
      },

      submit () {
        const { status, comment } = this;
        this.$emit("submit", { status, comment });
        this.$nextTick(() => {
          this.$emit("close");
        });
      }
    }
  };
</script>

<style scoped lang="scss">

</style>