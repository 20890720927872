<template>
    <div
        class="is-flex"
        style="column-gap: .5rem;">
        <LTooltip
            :label="$t(`admin.complaints.table.buttons.similarComplaintsAmount`, { amount: row.similarComplaintsAmount })"
            position="is-left"
            type="is-dark">
            <router-link
                target="_blank"
                :to="{
                    name: 'admin:complaints',
                    query: { filters: encodeFilters({
                        offerId: row.offer.id,
                        webmasterId: row.webmaster.id,
                        datepicker: {
                            dateStart: null,
                            dateEnd: datepicker().dateEnd
                        }
                    }) }
                }">
                <b-button
                    icon-left="history"
                    rounded
                    size="is-small"
                    type="is-gray is-light">
                </b-button>
            </router-link>
        </LTooltip>
        <Permissions :permissions="['STATISTICS.VIEW', 'STATISTICS.VIEW.WEBMASTERS.OWN', 'TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT']">
            <LTooltip
                :label="$t(`admin.complaints.table.buttons.analyseTraffic`)"
                position="is-left"
                type="is-dark">
                <b-button
                    :loading="loadingRowId === row.id"
                    icon-left="area-chart"
                    rounded
                    size="is-small"
                    type="is-gray is-light"
                    @click="goToAnalyseTraffic">
                </b-button>
            </LTooltip>
        </Permissions>

        <Permissions :permissions="['TRAFFIC_COMPLAINT.MANAGE', 'TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT']">
            <LTooltip
                v-if="isComments"
                :label="$t('admin.complaints.table.buttons.addComment')"
                position="is-left"
                class="is-relative"
                type="is-success">
                <b-button
                    :loading="loadingRowId === row.id"
                    icon-left="comments"
                    rounded
                    size="is-small"
                    type="is-success is-light"
                    @click="openModalComment">
                </b-button>
                <span
                    v-if="row.hasNewComment"
                    class="item-point">
                </span>
            </LTooltip>
        </Permissions>

        <Permissions :permissions="['TRAFFIC_COMPLAINT.MANAGE']">
            <LTooltip
                v-if="isNew"
                :label="$t(`admin.complaints.table.buttons.toConsiderate`)"
                class="mr-2"
                position="is-left"
                type="is-info">
                <b-button
                    :loading="loadingRowId === row.id"
                    icon-left="thumbtack"
                    rounded
                    size="is-small"
                    type="is-info is-light"
                    @click="complaintToConsiderate">
                </b-button>
            </LTooltip>

            <LTooltip
                v-else-if="hasBinded && isConsideration"
                :label="$t(`admin.complaints.table.buttons.toFinalize`)"
                position="is-left"
                type="is-warning">
                <b-button
                    :loading="loadingRowId === row.id"
                    icon-left="gavel"
                    rounded
                    size="is-small"
                    type="is-warning is-light"
                    @click="isActiveComplaintsFinalizeModal = true">
                </b-button>
            </LTooltip>

            <LTooltip
                v-else-if="isDispute && hasCloseTrafficComplaint && hasBinded"
                :label="$t(`admin.complaints.table.buttons.closeComplaint`)"
                position="is-left"
                type="is-warning">
                <b-button
                    :loading="loadingRowId === row.id"
                    icon-left="check-circle"
                    rounded
                    size="is-small"
                    type="is-warning is-light"
                    @click="closeTrafficComplaint">
                </b-button>
            </LTooltip>
        </Permissions>
        <LTooltip
            v-if="canDelete"
            :label="$t(`admin.complaints.table.buttons.deleteComplaint`)"
            position="is-left"
            type="is-danger">
            <b-button
                :loading="loadingRowId === row.id"
                icon-left="trash-alt"
                rounded
                size="is-small"
                type="is-danger is-light"
                @click="deleteTrafficComplaint">
            </b-button>
        </LTooltip>
        <ComplaintFinalizeModal
            :is-active.sync="isActiveComplaintsFinalizeModal"
            :is-loading="isLoadingFinalize"
            :row="row"
            @submit="complaintFinalize">
        </ComplaintFinalizeModal>
    </div>
</template>

<script>
  import { StatusComplaintsEnum } from "@core/store/types/admin/complaints/enums/StatusComplaintsEnum";
  import { mapActions, mapState } from "vuex";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import Permissions from "@/components/Common/Permissions.vue";
  import {
    CLOSE_TRAFFIC_COMPLAINT, DELETE_TRAFFIC_COMPLAINT,
    UPDATE_COMPLAINT_CONSIDERATE,
    UPDATE_COMPLAINT_FINALIZE,
    UPDATE_FORM, UPDATE_MODAL_ACTIVE
  } from "@core/store/action-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_FILTERS, UPDATE_INPUT } from "@core/store/mutation-constants";
  import ComplaintCloseTrafficModalTemplate from "./ComplaintCloseTrafficModalTemplate.vue";
  import { momentToDate } from "@core/helpers/momentToDate";
  import moment from "moment";
  import { datepicker, datepickerFormat } from "@core/helpers/datepicker";
  import { hasPermissions } from "@core/mixins/permissions";
  import { encodeFilters } from "@core/filters";
  import ComplaintFinalizeModal from "@/components/Admin/Complaints/Views/ComplaintFinalizeModal.vue";

  export default {
    name: "ComplaintsTableActions",
    components: { ComplaintFinalizeModal, Permissions },
      
    props: {
      row: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        loadingRowId: null,
        isActiveComplaintsFinalizeModal: false
      };
    },
      
    computed: {
      ...mapState({
        hasCloseTrafficComplaint: ({ auth }) => !hasPermissions(["TRAFFIC_COMPLAINT.MANAGE.CALL_CENTER_COMPLAINT"], auth.userPermissions) ||
          hasPermissions(["TRAFFIC_COMPLAINT.MANAGE"], auth.userPermissions),

        isAffiliate: ({ auth }) => hasPermissions(["INCIDENCE.VIEW"], auth.userPermissions),

        canComplaintManage: ({ auth }) => hasPermissions(["TRAFFIC_COMPLAINT.MANAGE"], auth.userPermissions)
      }),

      ...mapFields("admin/complaints/complaintsChatModal", {
        fields: ["trafficComplaintId"],
        base: "input",
        action: UPDATE_INPUT
      }),

      ...mapState("admin/profile", {
        hasBinded ({ user }) {
          return user.id === this.row.executor?.id;
        },

        isCreator ({ user }) {
          return user.id === this.row.creator?.id;
        }
      }),

      canDelete () {
        return this.isAffiliate && this.isNew && this.isCreator ||
          this.canComplaintManage && (this.isConsideration || this.isDispute) && this.hasBinded && !this.isCreator;
      },

      isNew () {
        return this.row.status === StatusComplaintsEnum.NEW;
      },

      isConsideration () {
        return this.row.status === StatusComplaintsEnum.CONSIDERATION;
      },

      isDispute () {
        return this.row.status === StatusComplaintsEnum.DISPUTE_CALL_CENTER;
      },
      
      isComments () {
        return this.row.status !== StatusComplaintsEnum.NEW && this.row.status !== StatusComplaintsEnum.CONSIDERATION;
      },

      isLoadingFinalize () {
        return this.$wait(`admin/complaints/${ UPDATE_COMPLAINT_FINALIZE }`);
      },

      isLoadingComplaintsClose () {
        return this.$wait(`admin/complaints/${ CLOSE_TRAFFIC_COMPLAINT }`);
      }
    },

    methods: {
      datepicker,
      ...mapActions("admin/complaints", {
        UPDATE_COMPLAINT_CONSIDERATE,
        UPDATE_COMPLAINT_FINALIZE,
        CLOSE_TRAFFIC_COMPLAINT,
        DELETE_TRAFFIC_COMPLAINT
      }),

      ...mapActions("admin/complaints/complaintsChatModal", {
        UPDATE_FORM,
        UPDATE_MODAL_ACTIVE
      }),
        
      async complaintToConsiderate () {
        const { id: complaintId } = this.row;
        this.loadingRowId = complaintId;
        try {
          this.loadingRowId = complaintId;
          await actionWithToast(this.UPDATE_COMPLAINT_CONSIDERATE(complaintId), "admin.complaints.table.messages.considerate");
        } finally {
          this.loadingRowId = null;
        }
      },

      async complaintFinalize (input) {
        const { id: complaintId } = this.row;
        try {
          this.loadingRowId = complaintId;
          await actionWithToast(this.UPDATE_COMPLAINT_FINALIZE({ complaintId, ...input }), "admin.complaints.table.messages.finalize");
          this.isActiveComplaintsFinalizeModal = false;
        } finally {
          this.loadingRowId = null;
        }
      },

      openModalComment () {
        const {
          id,
          intId,
          offer,
          webmaster,
          requiredApprove,
          positiveConversion,
          negativeConversion,
          positiveStartDate,
          positiveEndDate,
          negativeStartDate,
          negativeEndDate,
          softCapacity,
          status
        } = this.row;

        this.UPDATE_MODAL_ACTIVE(true);
        this.trafficComplaintId = id;

        this.UPDATE_FORM({
          status,
          offer,
          webmaster,
          intId,
          softCapacity,
          requiredApprove,
          positivePeriod: [positiveStartDate, positiveEndDate],
          negativePeriod: [negativeStartDate, negativeEndDate],
          conversions: [positiveConversion, negativeConversion]
        });
      },

      closeTrafficComplaint () {
        const { id: complaintId } = this.row;
        this.$buefy.modal.open({
          parent: this,
          component: ComplaintCloseTrafficModalTemplate,
          hasModalCard: true,
          props: {
            isLoading: this.isLoadingComplaintsClose
          },
          events: {
            submit: async (input) => {
              try {
                this.loadingRowId = complaintId;
                await actionWithToast(this.CLOSE_TRAFFIC_COMPLAINT({ complaintId, ...input }), "admin.complaints.modal.messagesClose");
              } finally {
                this.loadingRowId = null;
              }
            }
          }
        });
      },

      deleteTrafficComplaint () {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.complaints.modal.delete.title"),
          message: this.$t("admin.complaints.modal.delete.message"),
          confirmText: this.$t("admin.complaints.modal.delete.confirm"),
          cancelText: this.$t("common.buttons.cancel"),
          type: "is-danger is-light",
          onConfirm: async () => {
            await actionWithToast(
              this.DELETE_TRAFFIC_COMPLAINT(this.row.id),
              "admin.complaints.table.messages.delete"
            );
          }
        });
      },
        
      normalizeDate (start, end) {
        return datepickerFormat({ dateStart: momentToDate(moment(start)), dateEnd: momentToDate(moment(end)) });
      },

      async goToAnalyseTraffic () {
        const {
          positiveStartDate,
          positiveEndDate,
          negativeStartDate,
          negativeEndDate,
          currency,
          offer: { id: offerId },
          webmaster: { id: webmasterId }
        } = this.row;

        const query = {
          comparisonPeriods: !!positiveStartDate,
          firstPeriod: positiveStartDate
            ? this.normalizeDate(positiveStartDate, positiveEndDate)
            : this.normalizeDate(negativeStartDate, negativeEndDate),
          secondPeriod: positiveStartDate
            ? this.normalizeDate(negativeStartDate, negativeEndDate)
            : null,
          currency,
          offerId,
          webmasterId
        };

        await this.$store.dispatch(`admin/statistics/trafficAnalysis/${ UPDATE_FILTERS }`, query);
        const { href } = this.$router.resolve({
          name: "admin:statistics:trafficAnalysis",
          query: { filters: encodeFilters(query) }
        });
        open(href, "_blank");
      },
      encodeFilters
    }
  };
</script>

<style scoped lang="scss">
    $size-point: 8px;

    .item-point {
        position: absolute;
        top: -1px;
        left: 23px;
        width: $size-point;
        height: $size-point;
        border-radius: 50%;
        //noinspection SassScssResolvedByNameOnly
        background-color: $danger !important;
    }
</style>